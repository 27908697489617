<template>
  <InstallBanner v-if="showInstallBanner" @close="closeBanner" />
  <router-view></router-view>
  <notifications />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import InstallBanner from './components/InstallBanner.vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const installPromptEvent = ref(null);
const showInstallBanner = ref(false);
const isStandalone = ref(false); // Variable to track standalone mode
const deviceType = ref('');

// زمان حداقل برای نمایش دوباره بنر نصب (مثلاً 1 روز)
const MIN_DISPLAY_INTERVAL = 1 * 24 * 60 * 60 * 1000; // 1 روز به میلی‌ثانیه

// Function to detect device type
const detectDeviceType = () => {
  const ua = navigator.userAgent;
  if (/Android/i.test(ua)) {
    return 'android';
  } else if (/iPhone|iPad|iPod/i.test(ua)) {
    return 'ios';
  } else {
    return 'desktop';
  }
};

// Function to check whether the banner should be displayed based on the last display time
const shouldShowInstallBanner = () => {
  const lastShownTime = localStorage.getItem('installBannerLastShown');
  const now = new Date().getTime();
  
  // اگر بنر قبلاً نمایش داده نشده یا زمان کافی گذشته باشد، بنر را نمایش دهید
  return !lastShownTime || now - lastShownTime > MIN_DISPLAY_INTERVAL;
};

onMounted(() => {
  deviceType.value = detectDeviceType();
  localStorage.setItem('deviceType',deviceType.value)
  isStandalone.value = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

  if (!isStandalone.value && shouldShowInstallBanner()) {
    if (deviceType.value === 'android' || deviceType.value === 'desktop') {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        installPromptEvent.value = e;
        showInstallBanner.value = true;
        //عدم نمایش تور در زمانی که پنل نصب نمایش داده میشود
        const t= localStorage.getItem('tour');
         if(t === ''){
          localStorage.setItem('tour', '');
        }
        else if(!t || t !==''){
          localStorage.setItem('tour', 'showBanner');
        }



        // ذخیره زمان نمایش بنر
        localStorage.setItem('installBannerLastShown', new Date().getTime());
      });
    } else if (deviceType.value === 'ios') {
      // Show install banner on iOS with manual instructions
      showInstallBanner.value = true;
      // ذخیره زمان نمایش بنر
      localStorage.setItem('installBannerLastShown', new Date().getTime());
    }
  }
});

const closeBanner = () => {
  showInstallBanner.value = false;
  const t= localStorage.getItem('tour');
  if(t =='showBanner'){
    localStorage.setItem('tour', 'home');
    router.go({ name: 'HomePage' })
    localStorage.setItem('menu','home')
  }

};
</script>

<style>
.install-btn {
  padding: 0.5rem 1rem;
  font-size: 16px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: fixed;
  bottom: 20px; /* Move to bottom of the page */
  left: 60px; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for centering */
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a shadow for a subtle 3D effect */
  background-color: white; /* Set background color to white */
  color: #007bff; /* Change text color to match the button border */
  border: 2px solid #007bff; /* Add a border with the same color as the background */
}

.install-btn:hover {
  background: #f1f1f1; /* Slightly change background on hover */
  color: #0056b3; /* Change text color on hover */
  border-color: #0056b3; /* Change border color on hover */
}
</style>
