// v-prevent-multiple-clicks.js
export default {
    mounted(el, binding) {
      el.addEventListener('click', () => {
        if (!el.disabled) {
          el.disabled = true;
          setTimeout(() => {
            el.disabled = false;
          }, binding.value || 2500); // پیش‌فرض: 2.5 ثانیه
        }
      });
    }
  };
  