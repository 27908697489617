<template>
  <div class="install-overlay">
    <div class="install-content">
      <!-- <button v-prevent-multiple-clicks class="close-button" @click="$emit('close')">✕</button> -->
      <img class="install-logo" src="../../public/vesta-logo.jpg"/>
      <span style="font-weight: bold; font-size: 20px;">
        ناوگام، سامانه مدیریت حسابداری ناوگان
      </span>
      <div v-if="deviceType === 'android'" class="install-steps">
        <h2>نصب بر روی Android</h2>
        <p>1. منوی مرورگر را باز کنید.</p>
        <p>2. گزینه "Add to Home screen" را انتخاب کنید.</p>
        <p>3. دستورات را دنبال کنید.</p>
      </div>

      <div v-if="deviceType === 'ios'" class="install-steps">
        <h2>نصب بر روی iPhone/iPad</h2>
        <p>1. منوی Safari را باز کنید.</p>
        <p>2. گزینه "Add to Home Screen" را انتخاب کنید.</p>
        <p>3. دستورات را دنبال کنید.</p>
      </div>

      <div v-if="deviceType === 'desktop'" class="install-steps">
        <h2>نصب بر روی دسکتاپ</h2>
        <p>1. دکمه نصب در نوار آدرس مرورگر را بزنید.</p>
        <p>2. دستورات را دنبال کنید.</p>
      </div>

      <!-- <button v-prevent-multiple-clicks class="install-btn" v-if="deviceType !== 'ios'" @click="$emit('install')">
         متوجه شدم
      </button> -->
      <button v-prevent-multiple-clicks class="sub-btn " @click="$emit('close')">
        <span class="">
          متوجه شدم
        </span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const deviceType = ref('');

const detectDeviceType = () => {
  const ua = navigator.userAgent;
  if (/Android/i.test(ua)) {
    return 'android';
  } else if (/iPhone|iPad|iPod/i.test(ua)) {
    return 'ios';
  } else {
    return 'desktop';
  }
};

onMounted(() => {
  deviceType.value = detectDeviceType();
});
</script>

<style scoped>
.install-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.8); */
  background: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  text-align: center;
}
.install-logo {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  width: 200px;
  height: 150px;
}
.install-content {
  /* background: #333; */
  padding: 2rem;
  border-radius: 8px;
  max-width: 100%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: black;
  font-size: 24px;
  cursor: pointer;
}
.install-steps{
  margin-top: 20px;
  border: #bac6e9 1px solid;
  padding: 15px;
  border-radius: 7px;
}
.install-steps h2 {
  margin-bottom: 1rem;
}

.sub-btn {
  padding: 0.5rem 1rem;
  font-size: 16px;
  background: #33679e !important;
  color: white;
  border: none;
  border-radius: 5px !important;
  cursor: pointer;
  margin-top: 1rem;
  width: 100%;
  right:0 !important;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.sub-btn:hover {
  background: #1d446e;
}
</style>
