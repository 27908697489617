import { convertToEnglish } from '@/utils/utils';

export default {
  beforeMount(el) {
    el.addEventListener('input', () => {
      const originalValue = el.value;
      const convertedValue = convertToEnglish(originalValue).replace(/\D/g, ''); // Only keep numeric characters

      // اگر مقدار تغییر کرده است، فقط رویداد جدید ارسال شود
      if (originalValue !== convertedValue) {
        el.value = convertedValue;
        el.dispatchEvent(new Event('input')); // Ensures v-model gets updated
      }
    });
  }
};
